<template>
  <div class="root">
    <NavBar :showBack="true" title="รายละเอียดโปรโมชั่น" />
    <ValidationObserver ref="form">
      <form>
        <div class="group">
          <label>ชื่อโปรโมชั่น <span class="required">*</span></label>
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <input v-model="form.name" type="text" />
            <div class="errorMsg">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="group">
          <label>วันที่เริ่ม <span class="required">*</span></label>

          <ValidationProvider
            name="startTime"
            rules="required"
            v-slot="{ errors }"
          >
            <label class="input labelDateInput">
              {{ toDate(form.startTime) }}
              <input v-model="form.startTime" type="datetime-local" />
            </label>
            <div class="errorMsg">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="group">
          <label>วันที่สิ้นสุด <span class="required">*</span></label>

          <ValidationProvider
            name="endTime"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="input labelDateInput">
              {{ toDate(form.endTime) }}
              <input v-model="form.endTime" type="datetime-local" />
              <div class="errorMsg">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
          <div v-if="endTimeLessThenStartTime" class="errorMsg">
            วันที่สิ้นสุดต้องมากกว่าวันที่เริ่ม
          </div>
        </div>
        <div class="group">
          <div class="activeSection">
            <label>เปิดใช้งาน</label>
            <SlideSwitch @click="activeChange" :checked="form.active" />
          </div>
        </div>

        <div class="group bottomSection">
          <div class="btn confirmBtn" @click="submit()">บันทึกข้อมูล</div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import { GET, POST, PUT } from "@/utils/http";
import SlideSwitch from "@/components/SlideSwitch";

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

// Override the default message.
extend("required", {
  ...required,
  message: "กรุณากรอกข้อมูลให้ถูกต้อง",
});
export default {
  props: { id: String },
  components: { NavBar, SlideSwitch },
  data: () => ({
    form: {
      name: "",
      startTime: "",
      endTime: "",
      active: false,
    },
  }),
  computed: {
    endTimeLessThenStartTime() {
      return new Date(this.form.endTime) < new Date(this.form.startTime);
    },
  },
  methods: {
    activeChange(rs) {
      this.form.active = rs.value;
    },
    toDate(val) {
      try {
        return new Intl.DateTimeFormat("en-GB", {
          day: "numeric",
          year: "numeric",
          month: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        }).format(new Date(val));
      } catch (error) {
        return null;
      }
    },
    async getData() {
      window.loading(true);
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/promotions/${this.id}`
      );

      window.loading(false);

      if (status !== 200) {
        window.$alert("ดำเนินการไม่สำเร็จ กรุณลองใหม่ภายหลัง");
        return;
      }

      this.form = {
        name: body.name,
        active: body.active,
        ...(body.startTime && { startTime: body.startTime.slice(0, 16) }),
        ...(body.endTime && { endTime: body.endTime.slice(0, 16) }),
      };
    },
    async submit() {
      const formSuccess = await this.$refs.form.validate();
      if (!formSuccess || this.endTimeLessThenStartTime) {
        window.$alert("กรุณากรอกข้อมูลให้ครบและถูกต้อง");
        return;
      }

      if (!(await window.$alert("ต้องการบันทึกหรือไม่?", "confirm"))) {
        return;
      }

      const payload = {
        ...this.form,
        startTime: (this.form.startTime + ":00").replace("T", " "),
        endTime: (this.form.endTime + ":00").replace("T", " "),
      };

      window.loading(true);

      const { status } = this.id
        ? await PUT(
            `/api/shops/${this.$myShop.id}/promotions/${this.id}`,
            payload
          )
        : await POST(`/api/shops/${this.$myShop.id}/promotions`, payload);
      // 2022-08-01 00:00:00
      window.loading(false);

      if (status !== 200) {
        await window.$alert("ไม่สามารถดำเนินการได้ขณะนี้");
      }

      this.$router.back();
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
.root {
  padding-top: 50px;
}
.addBtn {
  background: var(--primary-color);
  color: #fff;
  margin: 0;
  padding: 8px 0;
  margin-left: auto;
  width: 95px;
  font-size: 13px;
}

select,
input,
textarea,
.input {
  padding: 10px 8px;
  width: 100%;
  font-size: 16px;
  max-width: 400px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 8px;
  min-height: 45px;
  color: rgb(63, 63, 63);
  display: block;
  margin: 0 auto;
}

.input {
  display: flex;
  align-items: center;
  min-height: 45px;
}

input:read-only {
  background-color: #eaeaea;
}

.comfirmBtn {
  margin: 30px auto 20px auto;
  background: var(--primary-color);
  color: #fff;
}

.group {
  padding: 10px 15px;
  position: relative;
  > label {
    margin-bottom: 10px;
    display: block;
  }
  .required {
    color: red;
  }
}

.errorMsg {
  margin: 10px;
  color: crimson;
  font-size: 14px;
}

.confirmBtn {
  background: var(--primary-color);
  color: #fff;
  margin: 20px auto;
  max-width: 400px;
}

.bottomSection {
  margin-top: 40px;
}

.activeSection {
  display: flex;
  align-items: center;
  gap: 12px;
}

// .startTimeInput {
//   position: absolute;
//   opacity: 0;

//   &::-webkit-calendar-picker-indicator {
//     position: absolute;
//     width: 100%;
//   }
// }

.labelDateInput {
  position: relative;
}

input[type="datetime-local"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}
</style>