<template>
  <label class="switch">
    <input
      type="checkbox"
      :checked="checked"
      @change="clickToggle($event.target.checked)"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: {
    checked: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    clickToggle(check) {
      this.$emit("click", { value: check });
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

// input:focus + .slider {
//   box-shadow: 0 0 1px var(--primary-color);
// }

input:checked + .slider:before {
  // -webkit-transform: translateX(18px);
  // -ms-transform: translateX(18px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>